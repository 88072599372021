<template>
  <div class="page">
    <div class="container">
      <div class="header">
        <div class="icon"></div>
        <div class="title">{{ $t('userPage.title') }}</div>
        <div class="search">
          <el-button class="button" type="primary" @click="handleCreate">创建主持人</el-button>
        </div>
      </div>
      <div class="content">
        <div>
          <el-table
            class="table"
            :data="tableData"
            v-loading="listLoading"
            header-cell-class-name="header"
            style="width: 100%"
          >
            <el-table-column label="序号" align="center" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="username" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="statusText" label="用户状态" align="center">
            </el-table-column>
            <el-table-column prop="userId" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center">
            </el-table-column>
            <el-table-column
              label="操作"
              width="120"
              align="center"
            >
              <template v-slot="scope">
                <!-- <el-button type="primary" link @click="handleDetail(scope.row)">详情</el-button> -->
                <!-- <el-button type="primary" link @click="handleEdit(scope.row)">编辑</el-button> -->
                <el-button v-if="scope.row.status === '3'" type="primary" link @click="handleActivate(scope.row)">激活</el-button>
                <el-button type="danger" link @click="handleDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <common-page
            v-model:current-page="pageObj.page"
            v-model:page-size="pageObj.size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </common-page> -->
        </div>
      </div>
    </div>
    <!-- 创建和修改弹窗 -->
    <el-dialog
      ref="createDialog"
      class="create-dialog"
      :show-close="false"
      :title="createDialog.title"
      :close-on-click-modal = false
      v-model="createDialog.show"
      width="40vw"
      center
    >
      <div class="main">
        <el-form
          ref="createForm"
          label-position="right"
          label-width="120px"
          :model="createForm"
          :rules="createRules"
          style="width:100%"
        >
          <el-form-item prop="username" label="用户姓名：">
            <!-- <el-select style="width:100%" v-model="createForm.username" @change="userChange" placeholder="请选择用户">
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.username"
                :value="item.userId"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="createForm.username" maxlength="20" placeholder="请输入用户姓名"></el-input>
          </el-form-item>
          <el-form-item prop="userId" label="手机号码：">
            <el-input placeholder="手机号码" v-model="createForm.userId"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelCreate">取 消</el-button>
          <el-button :loading="submitLoading" type="primary" @click="submitCreateForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// import commonPage from '@/components/public/pagination.vue'
import { checkTelephone } from '@/utils/validate'
import { hostList, addHost, editHost, delHost, activateHost } from '@/api/api'
import { Phone } from '@element-plus/icons'
export default {
  // components: {
  //   commonPage
  // },
  data () {
    return {
      userList: [
        { username: '彭仁芳', userId: '13564996119' },
        { username: '李青', userId: '13765159540' },
        { username: '张维亮', userId: '13639059594' }
      ],
      tableData: [],
      pageObj: {
        page: 1,
        size: 10
      },
      createDialog: {
        title: '创建主持人',
        show: false,
        // 是否是编辑状态
        isEdit: false
      },
      createForm: {
        username: '',
        userId: ''
      },
      createRules: {
        username: [
          { required: true, message: '必填项不能为空', trigger: ['blur'] }
        ],
        userId: [
          { required: true, message: '必填项不能为空', trigger: 'blur' },
          { validator: checkTelephone, trigger: 'blur' }
        ]
      },
      submitLoading: false,
      listLoading: false,
      maxComperes: 19
    }
  },
  created () {
    this.getHostList()
  },
  methods: {
    getStatusText (status) {
      switch (status) {
        case '1': return '正常'
        case '2': return '注销'
        case '3': return '未激活'
        case '4': return '禁用'
        default: return ''
      }
    },
    getHostList () {
      this.listLoading = true
      hostList().then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list?.map(item => {
            const { status, ...rest } = item
            return {
              ...rest,
              status,
              statusText: this.getStatusText(status)
            }
          })
          this.maxComperes = parseInt(res.data.maxComperes)
        }
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    handleCurrentChange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      this.pageObj.page = 1
      console.log(val)
    },
    userChange (val) {
      this.createForm.userId = val
      for (let index = 0; index < this.userList.length; index++) {
        const element = this.userList[index]
        if (element.userId == val) {
          this.createForm.username = element.username
          break
        }
      }
    },
    handleCreate () {
      if (this.tableData.length >= this.maxComperes) {
        this.$message.warning(`最多只能创建${this.maxComperes}个支持人`)
        return
      }
      this.createDialog.title = '创建主持人'
      this.createDialog.isEdit = false
      this.createForm.userId = ''
      this.createForm.username = ''
      this.createDialog.show = true
    },
    handleEdit (item) {
      this.createDialog.title = '编辑主持人'
      this.createDialog.isEdit = false
      this.createDialog.show = true
      this.createForm.userId = item.userId
      this.createForm.username = item.username
    },
    resetDialog () {
      this.createDialog = {
        title: '创建主持人',
        show: false
      }
    },
    submitCreateForm () {
      this.submitLoading = true
      this.$refs.createForm.validate((valid) => {
        if (valid) {
          const params = {
            username: this.createForm.username,
            userId: this.createForm.userId,
            phone: this.createForm.userId
          }
          addHost(params).then(res => {
            if (res.code === 0) {
              this.$message.success('创建成功')
              this.submitLoading = false
              this.cancelCreate()
              this.getHostList()
            } else {
              this.submitLoading = false
            }
          }).catch(() => {
            this.submitLoading = false
          })
        } else {
          this.submitLoading = false
          return false
        }
      })
    },
    resetForm () {
      this.createForm = {
        username: '',
        userId: ''
      }
      this.$refs.createForm.clearValidate()
    },
    cancelCreate () {
      this.resetForm()
      this.createDialog.show = false
    },
    handleDetail (item) {
      this.$router.push({
        path: '/hostDetail',
        id: item.id
      })
    },
    handleActivate (item) {
      const params = {
        userId: item.userId
      }
      activateHost(params).then(res => {
        if (res.code === 0) {
          this.$message.success('发送激活信息成功')
          this.getHostList()
        }
      })
    },
    handleDel (item) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            userId: item.userId
          }
          delHost(params).then(res => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.getHostList()
            }
          })
        })
        .catch(() => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 1200px;
  background: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  .icon {
    width: 6px;
    height: 30px;
    background: $colorGreen;
    margin-right: 10px;
  }
  .title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .search {
    margin-left: auto;
  }
}
.create-dialog {
  .main {
    display: flex;
    justify-content: center;
  }
}
</style>
