import ajax from '@/utils/axios'
export const demo = (params) => {
    return ajax({
        url: '/user/garett/test/demo1',
        method: 'get',
        params
    })
}
// 主持人列表
export const hostList = (params) => {
    return ajax({
        url: '/compere',
        method: 'get',
        params
    })
}
// 添加主持人
export const addHost = (data) => {
    return ajax({
        url: '/compere',
        method: 'post',
        data
    })
}
// 修改主持人
export const editHost = (params) => {
    return ajax({
        url: '/user/garett/test/demo1',
        method: 'get',
        params
    })
}
// 删除主持人
export const delHost = (data) => {
    return ajax({
        url: '/compere',
        method: 'delete',
        data
    })
}
// 激活主持人
export const activateHost = (data) => {
    return ajax({
        url: '/compere',
        method: 'put',
        data
    })
}
// 主持人列表
export const availableHostList = (params) => {
    return ajax({
        url: '/compere/actions',
        method: 'get',
        params
    })
}

// 会议列表(开启和进行中)
// export const meetingListOpen = (params) => {
//   return ajax({
//     url: '/meeting',
//     method: 'get',
//     params
//   })
// }

// 会议列表(开启)
export const meetingListOpen = (params) => {
    return ajax({
        url: '/corp/preStart',
        method: 'get',
        params
    })
}

// 会议列表(进行中)
export const meetingListInProgress = (params) => {
    return ajax({
        url: '/corp/online',
        method: 'get',
        params
    })
}

// 会议列表(关闭)
export const meetingListClose = (params) => {
    return ajax({
        url: '/corp/ended',
        method: 'get',
        params
    })
}
// 添加会议
export const addMeeting = (data) => {
    return ajax({
        url: '/meeting',
        method: 'post',
        data
    })
}
// 修改会议
export const editMeeting = (data) => {
    return ajax({
        url: '/meeting',
        method: 'put',
        data
    })
}
// 删除会议
export const delMeeting = (data) => {
    return ajax({
        url: '/meeting/cancel',
        method: 'put',
        data
    })
}
// 会议详情
export const meetingDetail = (data) => {
    return ajax({
        url: `/meeting/${data.userId}/${data.meetingId}`,
        method: 'get',
        params: data
    })
}
// 获取用户列表（嘉宾）
export const userList = (params) => {
    return ajax({
        url: '/org',
        method: 'get',
        params
    })
}
// 我的会议列表
export const getMyMeetingList = (params) => {
    return ajax({
        url: '/meeting/info',
        method: 'get',
        params
    })
}

// 根据机构名字或者机构编码模糊查询
export const queryOrg = (params) => {
    return ajax({
        url: '/org/find',
        method: 'get',
        params
    })
}
//根据用户姓名、用户编码、机构号来模糊查询人员
export const queryUser = (params) => {
    return ajax({
        url: '/user/find',
        method: 'get',
        params
    })
}
//根据id获取该节点下的所有子节点
export const getChildAll = (params) => {
    return ajax({
        url: '/org/all',
        method: 'get',
        params
    })
}